import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import ContactForm from "../../components/Form/ContactForm";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Layout from "../../components/Layout/Layout";


const ContactPage = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="Contact Us"
      description="If you have: suffered significant losses in one of your investments due to the actions 
      of the company or your investment advisor; been denied the full vacation pay, overtime or other 
      compensation due to you by your employer; been physically injured by an unsafe product; or had 
      your private information compromised by a company or organization, contact us for a free 
      consultation regarding your legal rights."
    >
      <Header
        colorScheme="light"
      />

      <div className="relative flex flex-col xl:flex-row xl:justify-between mb-16 pt-28 lg:pt-40 xl:pb-28 2xl:pb-96">
        <section className="flex-1 flex flex-col space-y-16 xl:space-y-24 xl:py-16 xl:bg-blue-dark xl:text-white contact-text">
          <div>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-thin tracking-wide">
              <span className="block">Looking for a consultation?</span>
            </h1>
            <p className="my-8 font-thin lg:text-lg">
              If you have: suffered significant losses in one of your investments due to the actions 
              of the company or your investment advisor; been denied the full vacation pay, overtime or other 
              compensation due to you by your employer; been physically injured by an unsafe product; or had 
              your private information compromised by a company or organization, contact us for a free 
              consultation regarding your legal rights.
            </p>
            <p className="mt-8 font-thin lg:text-lg">
              If you wish to be added to our contact list for a specific case, fill out the contact form
              located on the webpage for the specific case that you are interested in. We will email everyone 
              on the contact list for each case with periodic updates about that specific case.
            </p>
          </div>
          <div className="flex flex-col space-y-8">
            <div>
              <span className="block font-semibold">EMAIL</span>
              <span className="block font-thin"><a href="mailto:contact@knd.law">contact@knd.law</a></span>
            </div>
            <div>
              <span className="block font-semibold">PHONE</span>
              <span className="block font-thin"><a href="tel:+14165373529">+1 416 537 3529</a></span>
            </div>
            <div>
              <span className="block font-semibold">LOCATION</span>
              <span className="block font-thin">Suite 401, Yonge Eglinton Centre</span>
              <span className="block font-thin">2300 Yonge Street</span>
              <span className="block font-thin">Toronto, Ontario</span>
              <span className="block font-thin">Canada M4P 1E4</span>
            </div>
          </div>
        </section>
        <div className="w-full my-16 contact-image">
          <Img
            fluid={ file.childImageSharp.fluid }
            alt="Library"
          />
        </div>
        <section className="flex-1 xl:pt-32 contact-form">
          <ContactForm
            heading="Contact Us"
          />
        </section>
      </div>

      <Footer />
    </Layout>
  );
}

export default ContactPage;